<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">班级列表（班级参数）</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start; ">
          <div class="searchbox" style="margin-bottom:15px">
            <div title="班级编码" class="searchboxItem ci-full">
              <span class="itemLabel">班级编码:</span>
              <el-input
                v-model="selectDate.projectCode"
                placeholder="请输入班级编号"
                size="small"
                onkeyup="value=value.replace(/[^\w\.\/]/ig,'')"
                clearable
              />
            </div>
            <div title="班级名称" class="searchboxItem ci-full">
              <span class="itemLabel">班级名称:</span>
              <el-input
                v-model="selectDate.projectName"
                placeholder="请输入班级名称"
                size="small"
                clearable
              />
            </div>
            <div title="开班单位" class="searchboxItem ci-full">
              <span class="itemLabel">开班单位:</span>
              <el-input v-model="selectDate.compName" placeholder="请输入开班单位" size="small" clearable />
            </div>
            <div title="行政区划" class="searchboxItem ci-full">
              <span class="itemLabel">行政区划:</span>
              <el-cascader
                v-model="selectDate.areaId"
                :options="areatreeList"
                :props="propsarea"
                clearable
                filterable
                size="small"
              ></el-cascader>
            </div>
          </div>
          <div class="searchbox" style="margin-bottom:15px">
            <div title="状态" class="searchboxItem ci-full">
              <span class="itemLabel">状态:</span>
              <el-select
                v-model="selectDate.projectStudyTimeState"
                placeholder="请选择"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in classtatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="培训类型" class="searchboxItem ci-full">
              <span class="itemLabel">培训类型:</span>
              <tree
                ref="tree"
                @eventBtn="childBack"
                :ruleForm="ruleForm"
                size="small"
                @clearParams="clearParams"
                typeStu
              />
            </div>
            <div title="创建时间" class="searchboxItem ci-full">
              <span class="itemLabel">创建时间:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="selectDate.creationTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
          </div>
          <div class="searchbox">
            <div title="开始时间" class="searchboxItem ci-full">
              <span class="itemLabel">开始时间:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="selectDate.startTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <div title="结束时间" class="searchboxItem ci-full">
              <span class="itemLabel">结束时间:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="selectDate.endTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <div class="df">
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" fixed />
              <el-table-column
                label="班级编码"
                align="left"
                show-overflow-tooltip
                prop="projectCode"
                min-width="200px"
                fixed
              />
              <el-table-column
                label="班级名称"
                align="left"
                show-overflow-tooltip
                prop="projectName"
                width="200"
              />
              <el-table-column
                label="开班单位"
                align="left"
                show-overflow-tooltip
                prop="compName"
                width="200"
              />
              <el-table-column label="要求学时" align="right" show-overflow-tooltip prop="lessonNum" />
              <el-table-column label="行政区划" align="left" show-overflow-tooltip prop="areaName" />
              <el-table-column label="负责人" align="left" show-overflow-tooltip prop="projectUser" />
              <el-table-column
                label="开始时间"
                align="left"
                show-overflow-tooltip
                prop="startDate"
                minWidth="100"
              >
                <template slot-scope="scope">
                  <span>{{scope.row.startDate|momentDate}}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="结束时间"
                align="left"
                show-overflow-tooltip
                prop="endDate"
                minWidth="100"
              >
                <template slot-scope="scope">
                  <span>{{scope.row.endDate|momentDate}}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="报名截止"
                align="left"
                show-overflow-tooltip
                prop="signEndDate"
                minWidth="100"
              >
                <template slot-scope="scope">
                  <span>{{scope.row.signEndDate|momentDate}}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="状态"
                align="center"
                show-overflow-tooltip
                prop="projectStudyTimeState"
              >
                <template slot-scope="scope">
                  <span>{{$setDictionary("PROJECTSTATE", scope.row.projectStudyTimeState)}}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="创建时间"
                align="left"
                show-overflow-tooltip
                prop="createTime"
                minWidth="100"
              >
                <template slot-scope="scope">
                  <span>{{scope.row.createTime|momentDate}}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="培训类型"
                align="left"
                show-overflow-tooltip
                prop="trainTypeNamePath"
                width="150"
              />
              <el-table-column
                label="是否允许打印学时证明（或档案）"
                align="center"
                show-overflow-tooltip
                prop="allowPrint"
                width="120px"
                fixed="right"
              >
                <template slot-scope="scope">
                  <el-switch
                    class="selStyle"
                    v-model="scope.row.allowPrint"
                    width="40"
                    @change="getSwitch(scope.row.projectId)"
                  ></el-switch>
                  <span style="margin-left:4px">是</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="350px" fixed="right">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    style="padding:0 5px"
                    type="text"
                    size="mini"
                    @click="seeDetails(scope.row)"
                  >详情</el-button>
                  <el-button
                    type="text"
                    size="mini"
                    @click="openStartClass(scope.row.projectId,scope.row.projectSource,scope.row.auditState)"
                  >开课记录</el-button>
                  <el-button
                    type="text"
                    size="mini"
                    @click="studentInfo(scope.row.projectId)"
                  >学员信息</el-button>
                  <el-button
                    type="text"
                    size="mini"
                    :disabled="!scope.row.enableConfigParam"
                    @click="Classparameters(scope.row.projectId)"
                  >班级参数</el-button>
                  <el-button
                      type="text"
                      size="mini"
                      @click="downloadUserPic(scope.row.projectId)"
                  >认证照片下载</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
const className = "eduCertService";
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import tree from "@/components/treePopup";
import List from "@/mixins/List";
import moment from "moment";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "classListClassparameterY",
  components: {
    Empty,
    PageNum,
    tree
  },
  mixins: [List],
  data() {
    return {
      selectDate: {
        projectName: "", //班级名称
        projectCode: "", //班级编码
        compName: "", //开班单位
        areaId: "", //行政区域
        projectStudyTimeState: "", //状态
        creationTime: "", //创建时间
        startTime: "", //开始时间
        endTime: "", //开始时间
        endDateLong: "",
        pageNum: 1,
        pageSize: 10,
        startDateLong: ""
      },
      areatreeList: [],
      classtatusList: [],
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true
      },
      docsForm: {},
      selectedItem: {},
      docsOptions: {
        "10": "学时证明",
        "20": "学员培训档案"
      },
      ruleForm: {
        Trainingtype: ""
      },
      params: {}
    };
  },
  created() {},
  watch: {
    height(val) {
      this.$nextTick(() => {
        this.$refs.multipleTable.doLayout();
      });
    },
    $route: {
      handler: function(val, oldVal) {
        if (val.query.refresh) {
          this.getData();
        }
      },
      // 深度观察监听
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    })
  },
  mounted() {
    this.getTableHeight();
    this.getareatree();
    this.getclasstatusList();
  },
  methods: {
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    openStartClass(projectId,projectSource,auditState) {
      this.$router.push({
        path: "/web/classListCom/startClassRecord",
        query: {
          projectId,
          projectSource,
          auditState
        }
      });
    },
    getSwitch(value) {
      this.$post("/biz/project/updateClassHourCertificate", {
        projectId: value
      }).then(res => {
        if (res.status == "0") {
          this.getData(-1);
        }
      });
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        projectName: this.selectDate.projectName,
        projectCode: this.selectDate.projectCode,
        compName: this.selectDate.compName,
        areaId: this.selectDate.areaId,
        // isPause: this.selectDate.isPause,
        // isSubsidy: this.selectDate.isSubsidy,
        projectStudyTimeState: this.selectDate.projectStudyTimeState,
      // startDateLong: this.selectDate.creationTime
        //   ? this.selectDate.creationTime[0]
        //   : "",
        // endDateLong: this.selectDate.creationTime
        //   ? this.selectDate.creationTime[1]
        //   : "",
        // startDateStartLong: this.selectDate.startTime
        //   ? this.selectDate.startTime[0]
        //   : "",
        // startDateEndLong: this.selectDate.startTime
        //   ? this.selectDate.startTime[1]
        //   : "",
        // endStartDateLong: this.selectDate.endTime
        //   ? this.selectDate.endTime[0]
        //   : "",
        // endEndDateLong: this.selectDate.endTime
        //   ? this.selectDate.endTime[1]
        //   : ""
      };
      if (this.selectDate.creationTime) {
          params.startDate = this.selectDate.creationTime[0];
          params.endDate = this.selectDate.creationTime[1];
      }
      if (this.selectDate.startTime) {
          params.startDateStart = this.selectDate.startTime[0];
          params.startDateEnd = this.selectDate.startTime[1];
      }
      if (this.selectDate.endTime) {
          params.endStartDate = this.selectDate.endTime[0];
          params.endEndDate = this.selectDate.endTime[1];
      }
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      this.doFetch({
        url: "/biz/project/businessListPage",
        params,
        pageNum
      });
    },
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree").then(ret => {
        this.areatreeList = ret.data;
      });
    },
    getclasstatusList() {
      const classtatuslist = this.$setDictionary("PROJECTSTATE", "list");
      const list = [];
      for (const key in classtatuslist) {
        list.push({
          value: key,
          label: classtatuslist[key]
        });
      }
      this.classtatusList = list;
    },
    //详情
    seeDetails(row) {
      sessionStorage.setItem("projectId", row.projectId);
      sessionStorage.setItem("compId", row.compId);
      sessionStorage.setItem("projectName", row.projectName);
      sessionStorage.setItem("projectSource", row.projectSource);
      console.log(row.projectSource);
      this.$router.push({
        path: "/web/classDetailY",
        query: {
          projectId: row.projectId,
          stu: "view",
          hasGovRollCall: JSON.stringify(row.hasGovRollCall) || "false",
          hasRollCall: JSON.stringify(row.hasRollCall) || "false",
          projectSource:row.projectSource
        }
      });
    },
    // 学员信息
    studentInfo(projectId){
      this.$router.push({
        path:'/web/studentInfo',
        query:{
          projectId
        }
      })
    },
    // 班级参数
    Classparameters(projectId){
      this.$router.push({
        path:'/web/classparameters',
        query:{
          projectId,
          stu:'edit'
        }
      })
    },
    downloadUserPic(projectId) {
      this.$post("/biz/project/asyncDownloadUserPic", {
        projectId: projectId
      }).then(re => {
        if (re.status == "0") {
          if (!this.downloadItems.includes(re.data.taskId)) {
            this.$store.dispatch("pushDownloadItems", re.data.taskId);
          } else {
            this.$message.warning(
                "[" + re.data.fileName + "]已经申请下载,请耐心等待"
            );
          }
        } else {
          this.$message.error(re.message);
        }
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight -
        (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 3.25 + 3) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
    }
  },
  beforeRouteLeave: resetKeepAlive
};
</script>
<style lang="less">
.searchBox {
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
.el-switch__core:after {
  background-color: #fff;
}
</style>